:root {
  --primary-color-light-2: #eea0b2;
  --primary-color-light-1: #fb5984;
  --primary-color: #fa2f93;
  --primary-color-dark-1: #e32557;
  --primary-color-dark-2: #cf1143;

  --secondary-color-light-2: #4e4e78;
  --secondary-color-light-1: #2b2b65;
  --secondary-color: #000050;
  --secondary-color-dark-1: #000044;
  --secondary-color-dark-2: #00003a;

  --gray-color-2: #c4c4c4;
  --gray-color: #7e7e7e;
  --black-color: #474747;
  --black-color-1: #333333;
  --black-color-2: #242424;

  --white-color: #ffffff;
  --success-color: #28c48c;
  --danger-color: #eb5757;
  --info-color: #f2994a;

  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;

  /*variables of text size*/
  --font-size-extraSmall-mobile: 14px;
  --font-size-small-mobile: 14px;
  --font-size-medium-mobile: 14px;
  --font-size-large-mobile: 14px;
  --font-size-extraLarge-mobile: 14px;

  --font-size-extraSmall-desktop: 10px;
  --font-size-small-desktop: 10px;
  --font-size-medium-desktop: 12px;
  --font-size-large-desktop: 14px;
  --font-size-extraLarge-desktop: 16px;

  /*variables of title size*/
  --title-size-extraSmall-mobile: 14px;
  --title-size-small-mobile: 16px;
  --title-size-medium-mobile: 20px;
  --title-size-large-mobile: 24px;
  --title-size-extraLarge-mobile: 32px;

  --title-size-extraSmall-desktop: 16px;
  --title-size-small-desktop: 20px;
  --title-size-medium-desktop: 24px;
  --title-size-large-desktop: 32px;
  --title-size-extraLarge-desktop: 40px;
}
