.card-partner-list {
    margin-left: 0;
    margin-top: 26px;
}

@media (min-width: 575px) {
    .card-partner-list {
        margin-left: 26px;
        margin-top: 26px;
    }

    .card-partner-list:nth-child(4n+1) {
        margin-left: 0;
        margin-top: 26px;
    }
}

.space-button {
    margin-left: 16px;
}

.space-button:first-child {
    margin-left: 0;
}
